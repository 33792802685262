.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  color: #fff;
  background: rgb(15, 5, 29) !important;
  background-image: linear-gradient(
    135deg,
    rgba(15, 5, 29, 1) 0%,
    rgba(15, 6, 52, 1) 49%,
    rgba(126, 46, 164, 1) 100%
  );
}
/* Custom range slider styling */
.slider-container {
  position: relative;
  width: 100%;
}

.custom-slider{
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 18px;
  border-radius: 5rem;
  outline: none;
  background: linear-gradient(to right, #ffffff 50%, #7e2ea4 50%);
  transition: background 0.3s ease-in-out;
  position: relative;
}
.curve-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 18px;
  border-radius: 5rem;
  outline: none;
  background: linear-gradient(to right, #558919 50%, #7e2ea4 50%);
  transition: background 0.3s ease-in-out;
  position: relative;
}

.custom-slider:hover {
  opacity: 1;
}
.swiper-wrapper{
  justify-content: start !important;
}
.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 40px;
  background: url("./Assets/Images/slider_1.svg") no-repeat center;
  background-size: cover;
  cursor: pointer;
  position: relative;
}
.curve-slider::-webkit-slider-thumb {
 display: none;
}

.custom-slider::-moz-range-thumb {
  width: 40px;
  height: 40px;
  background: url("./Assets/Images/slider_1.svg") no-repeat center;
  background-size: cover;
  cursor: pointer;
  position: relative;
}

.value-label {
  position: absolute;
  top: 0;
  font-size: 15px;
  color: #000;
  width: 100%;
  text-align: center;
  pointer-events: none;
  z-index: 10;
  transition: left 0.3s ease-in-out;
}

@font-face {
  font-family: "lato";
  src: url("/src/Assets/font/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "lato-light";
  src: url("/src/Assets/font/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "lato-black";
  src: url("/src/Assets/font/Lato-Black.ttf") format("truetype");
}
@font-face {
  font-family: "latoBold";
  src: url("/src/Assets/font/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "MacondoSwashCaps-Regular";
  src: url("/src/Assets/font/MacondoSwashCaps-Regular.ttf") format("truetype");
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 55%);
  /* border-radius: 5px; */
}

::-webkit-scrollbar-thumb {
  /* border-radius: 5px; */
  -webkit-box-shadow: inset 0 0 10px #b46eff;
}

.custom_form {
  border: 1px solid #7e2ea4 !important;
  margin-right: 0;
  border-radius: 5px;
}
.custom_form svg {
  color: #fff;
}

.custom_form .MuiInputBase-root::before {
  border-bottom: 0 !important;
}

.custom_form .MuiInput-root:hover::before {
  border-bottom: 0;
}

.custom_form .MuiInputBase-root::after {
  border-bottom: 0;
}

.custom_form select {
  padding: 10px;
  color: #fff;
  font-family: "lato";
}

.custom_form option {
  padding: 5px !important;
}
.custom_form .custom_select option {
  background: #333 !important; /* Dark background color */
  color: #fff !important; /* Light text color */
  font-family: "lato";
}

.custom_input {
  /* width: 100%; */
  border: 1px solid #7e2ea4 !important;
  border-radius: 5px;
  color: #fff;
}

.custom_input .MuiFormControl-root:focus-visible {
  outline: none !important;
  border: none !important;
}

.custom_input .MuiFormControl-root:hover {
  border: none;
}

.custom_input .MuiFormControl-root:focus {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.custom_input .MuiInputBase-root::before,
.custom_input .MuiInputBase-root::after {
  display: none;
}

.custom_input input {
  padding: 8px 10px;
  font-size: 20px;
  border-radius: 10px;
  font-weight: 500;
  font-family: "lato";
  color: rgba(255, 255, 255, 0.8);
}

.custom_input input::placeholder {
  color: #fff !important;
  font-family: "lato";
  font-size: 16px;
}

.custom_input textarea {
  padding: 10px !important;
  color: #fff;
}

.custom_input textarea:focus {
  outline: none;
}

.custom_input textarea::placeholder {
  color: #fff;
}
.trade_box {
  background: rgba(126, 46, 164, 0.42);
  border-radius: 5px;
  padding: 0.5rem;
}

.trade_box .MuiFormControl-root {
  width: 100%;
  border: 1px solid #6f6f6f !important;
  border-radius: 3px;
  background: rgba(23, 22, 22, 0.46);
  color: #fff;
}

.trade_box .MuiFormControl-root:focus-visible {
  outline: none !important;
  border: none !important;
}

.trade_box .MuiFormControl-root:hover {
  border: none;
}

.trade_box .MuiFormControl-root:focus {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.trade_box .MuiInputBase-root::before,
.trade_box .MuiInputBase-root::after {
  display: none;
}

.trade_box .MuiFormControl-root input {
  padding: 5px;
  font-size: 18px;
  border-radius: 10px;
  font-weight: 500;
  font-family: lato !important;
  color: #aeaeae !important;
}

.trade_box .MuiFormControl-root input::placeholder {
  color: #fff !important;
  font-family: lato;
  font-size: 16px;
}

.trade_box .buy {
  background: #007721;
  color: rgba(255, 255, 255, 0.5);
  padding: 0.4rem 0.9rem;
  font-size: 16px;
  width: 100%;
  text-transform: capitalize;
  font-family: latoBold;
}

.trade_box .buy:hover {
  background: #007721;
}

.trade_box .sell {
  background: #912300;
  color: rgba(255, 255, 255, 0.5);
  padding: 0.4rem 0.9rem;
  font-size: 16px;
  width: 100%;
  text-transform: capitalize;
  font-family: latoBold;
}

.trade_box .sell:hover {
  background: #912300;
}

.trade_box .trade_btn {
  margin: 1.5rem 0 1rem;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  font-family: latoBold;
  transition: all 0.3s ease;
  text-transform: capitalize;
  width: 100%;
  padding: 6px;
  /* animation: Gradient 2s ease infinite; */
}
.button {
  border: 2px solid transparent !important;
}

.active {
  border-color: rgb(255 255 255 / 70%) !important;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.awesome {
  animation:colorchange 8s infinite alternate;
}

@keyframes colorchange {
  0% {
    
    color: #7E2EA4;
  }
  
  20% {
    
    color: #E106D8;
  }
  
  40% {
    
    color: #A632DD;
  }
  
  80% {
    
    color: #CC02FF;
  }
  
  100% {
    
    color: FFBBFC;
  }

}

/* Fade-in animation keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(60px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.reset_btn {
  background: rgba(255, 255, 255, 0.11) !important;
  color: #a3a3a3 !important;
  padding: 3px !important;
  line-height: 1.5 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  font-family: "LatoBold" !important;
}

.reset_btn:hover {
  background: rgba(255, 255, 255, 0.11);
}
.comment {
  border: 2px solid rgba(255, 255, 255, 0.22) !important;
  border-radius: 5px;
  width: 100%;
  background: rgb(126 46 164 / 15%);
  padding: 10px;
  color: #fff;
}
.comment:focus-visible {
  outline: none !important;
}
.comment::placeholder {
  color: #ffffffb5;
}